import axios from 'axios';

// Set event listeners
document.getElementById("contact-submit").addEventListener("click", sendMessage);
document.getElementById("navbarContacto").addEventListener("click", hideMessages);

let isValidForm;
let contactName;
let contactEmail;
let contactMessage;

async function sendMessage(event) {
    event.preventDefault();

    hideSuccessMessage();
    hideErrorMessage();
    hideWarningMessage();

    // Get elements
    contactName = document.getElementById("contactName").value;
    contactEmail = document.getElementById("contactEmail").value;
    contactMessage = document.getElementById("contactMessage").value;

    isValidForm = true;

    const allPromise = Promise.all([
        myPromise("name", contactName),
        myPromise("email", contactEmail),
        myPromise("message", contactMessage)
    ]);

    let emailBody = new Object();

    await allPromise.then((res) => {
        emailBody = res;

    }).catch((err) => {
        setWarningText(err);
    });

    if (isValidForm) {
        disableSendButton();

        await axios.post('https://func-gimf-contact-prod.azurewebsites.net/api/SendEmailNotification', {
            name: emailBody[0],
            email: emailBody[1],
            message: emailBody[2]
        }).then(function (response) {
            resetFormFields();
            displaySuccessMessage();
        }).catch(function (error) {
            displayErrorMessage();
        });

        enableSendButton();
    }
}

let myPromise = function (field, value) {
    return new Promise(function (myResolve, myReject) {
        if (value.trim() === "" || value.trim() === undefined) {
            isValidForm = false;
            myReject(`Required field ${field} is empty.`);
        } else {
            myResolve(value);
        }
    })
};

function disableSendButton() {
    document.getElementById("contact-submit").disabled = true;
    document.getElementById("contact-submit").innerHTML = `
    <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
    <span role="status">Enviando...</span>`
}

function enableSendButton() {
    document.getElementById("contact-submit").disabled = false;
    document.getElementById("contact-submit").innerHTML = "Enviar mensaje"
}

function setWarningText(text) {
    document.getElementById("warning-alert-text").innerText = text;
    document.getElementById("email-warning-alert").style.display = "flex";
}

function resetFormFields() {
    document.getElementById("contactName").value = "";
    document.getElementById("contactEmail").value = "";
    document.getElementById("contactMessage").value = "";
}

function displaySuccessMessage() {
    document.getElementById("email-success-alert").style.display = "block";
}

function displayErrorMessage() {
    document.getElementById("email-danger-alert").style.display = "block";
}

function hideSuccessMessage() {
    document.getElementById("email-success-alert").style.display = "none";
}

function hideErrorMessage() {
    document.getElementById("email-danger-alert").style.display = "none";
}

function hideWarningMessage() {
    document.getElementById("email-warning-alert").style.display = "none";
}

function hideMessages() {
    hideSuccessMessage();
    hideErrorMessage();
    hideWarningMessage();
}